//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TdIconBtn, TdBtn } from 'td-ui'

export default {
  name: 'FileCard',
  components: {
    TdIconBtn,
    TdBtn,
    IconCancel: () => import('@tada/icons/dist/IconXCircleM.vue'),
    FileIcon: () => import('@tada/icons/dist/IconFile.vue'),
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      requied: true,
    },
    loading: {
      type: Number,
      requied: true,
    },
  },
  computed: {
    fileLoadingPercent () {
      return (this.loading / this.size * 100).toFixed()
    },
    formattedLoading () {
      return this.formatBytes(this.loading)
    },
    formattedSize () {
      return this.formatBytes(this.size)
    },
  },
  methods: {
    formatBytes (bytes) {
      const kilobyte = 1024
      const megabyte = kilobyte * 1024
      const gigabyte = megabyte * 1024

      if (bytes >= gigabyte) {
        return (bytes / gigabyte).toFixed(2) + ' ГБ'
      } else if (bytes >= megabyte) {
        return (bytes / megabyte).toFixed(2) + ' МБ'
      } else if (bytes >= kilobyte) {
        return (bytes / kilobyte).toFixed(2) + ' КБ'
      } else {
        return bytes + ' байт'
      }
    },
    handleFileDelete () {
      this.$emit('file-delete', this.name)
    },
    handleFileLoadingCancel () {
      this.$emit('file-loading-cancel', this.name)
    },
  },
}
