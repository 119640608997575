//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
import { TdBtn } from 'td-ui'
import FileCard from './FileCard.vue'

export default {
  name: 'FileUploader',
  components: {
    TdBtn,
    FileCard,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    uploadUrl: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      files: [],
      uploadFiles: [],
      active: false,
      formData: null,
      showProgress: false,
    }
  },
  methods: {
    drop (event) {
      event.preventDefault()
      this.active = false

      const { files } = event.dataTransfer
      this.uploadFile(files[0])
    },
    handleDragEnter () {
      this.active = true
    },
    handleDragLeave () {
      this.active = false
    },
    uploadFile (file) {
      if (!file) return
      this.formData = new FormData()
      this.formData.append('file', file)
      this.files.push({ name: file.name, loading: 0 })
      this.showProgress = true

      axios.post(this.uploadUrl, this.formData, {
        onUploadProgress: ({ loaded, total }) => {
          this.files[this.files.length - 1].loading = loaded
          this.files[this.files.length - 1].size = total
          if (loaded === total) {
            this.uploadFiles.push({ name: file.name, loading: loaded, size: total })
            this.files = []
            this.showProgress = false
          }
        },
      }).then(({ data }) => this.$emit('upload-result', data))
        .catch(error => console.log(error))
    },
    handleFileDelete (fileName) {
      this.uploadFiles = this.uploadFiles.filter(file => file.name !== fileName)
      this.formData = null
      this.$emit('remove-file')
    },
    handleLoadingCancel (fileName) {
      this.files = this.files.filter(file => file.name !== fileName)
      this.formData = null
    },
  },
}
