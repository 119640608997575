//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import FileUploader from './FileUploader/'

export default {
  name: 'Step1',
  components: {
    FileUploader,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      uploadError: null,
      templateUploading: false,
    }
  },
  computed: {
    uploadUrl () {
      return api.contacts.getImportTemplateURL()
    },
  },
  methods: {
    handleContactParse (data) {
      const { result } = data
      this.$emit('contact-parse', result.contacts)
    },
    handleFileRemove () {
      this.$emit('remove-file')
    },
  },
}
